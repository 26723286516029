import React, { useEffect, useState } from 'react'
import Layout from '../Home'
import { hooks } from './hooks'
import { useSelector, useDispatch } from 'react-redux'
import { fetchNote, updateNote } from '../../store/healthCheckUpSlice'
import { isEmpty } from 'lodash'
import { Spin, Row, Col, Button, Radio, Image, Popconfirm, Checkbox, message } from 'antd'
import TextEditor from '../../components/TextEditor'
import { Container, EditorBox, Text, ColFlexCenter, InfoBox, FloatTitleBox, FloatTitleText } from './style'
import Avatar from '../../images/default_avatar.png'
import moment from 'moment'
import 'moment/locale/th'
import CheckUpLogsModal from '../../components/Modal/CheckUpLogsModal'
const note = (props) => {
  const [summaryNote, setSummaryNote] = useState('<br><br><br><br><br>')
  const [labNote, setLabNote] = useState('<br><br><br><br><br>')
  const [xrayNote, setXrayNote] = useState('<br><br><br><br><br>')
  const [labStatus, setLabStatus] = useState(null)
  const [adviceSelected, setAdviceSelected] = useState([])
  const [modifiedLogs, setModifiedLogs] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [patientInfo, setPatientInfo] = useState(false)

  const dispatch = useDispatch()
  const { events, params } = hooks()
  const healthCheckUpNotesId = props.match?.params?.healthCheckUpNotesId
  const [healthCheckUpNote, userInfo, errorMessage, isLoadingData] = useSelector((state) => {
    const { note, isLoadingData, errorMessage } = state.healthCheckUp
    const { userInfo } = state.user
    return [note, userInfo, errorMessage, isLoadingData]
  })

  useEffect(() => {
    const isNumberOnly = !isNaN(healthCheckUpNotesId)
    if (isNumberOnly) {
      dispatch(fetchNote(parseInt(healthCheckUpNotesId)))
    } else {
      props.history.push('/health-checkup')
    }
  }, [healthCheckUpNotesId])

  useEffect(() => {
    if (errorMessage) {
      props.history.push('/health-checkup')
    }
  }, [errorMessage])

  useEffect(() => {
    if (!isEmpty(healthCheckUpNote)) {
      const hieInfo = events.fecthUserInfoByCId(healthCheckUpNote?.cId)
      events.fetchDoctorAdvices()
      setSummaryNote(healthCheckUpNote?.summaryNote ?? '<br><br><br><br><br>')
      setLabNote(healthCheckUpNote?.labNote ?? '<br><br><br><br><br>')
      setXrayNote(healthCheckUpNote?.xrayNote ?? '<br><br><br><br><br>')
      setLabStatus(healthCheckUpNote?.labStatus ?? null)
      setAdviceSelected(healthCheckUpNote?.doctorAdvices ?? [])
      setModifiedLogs(healthCheckUpNote?.modifiedLogs ?? [])
      Promise.resolve(hieInfo).then((info) => setPatientInfo(info))
    }
  }, [healthCheckUpNote])

  const saveHealthCheckUp = () => {
    const newModifiedLogs = [...modifiedLogs, { doctorName: userInfo?.fullname, doctorId: userInfo?.id, date: new Date(), data: { summaryNote, labNote, xrayNote, doctorAdvices: adviceSelected } }]
    const params = {
      id: healthCheckUpNote?.id,
      data: { summaryNote, labNote, xrayNote, labStatus, doctorAdvices: adviceSelected, doctorName: userInfo?.fullname, doctorId: userInfo?.id, modifiedLogs: newModifiedLogs },
      callback: (updateStatus) => {
        if (updateStatus) {
          message.success('อัปเดตข้อมูลสำเร็จ')
        } else {
          message.error('อัปเดตข้อมูลล้มเหลว')
        }
      },
    }
    dispatch(updateNote(params))
  }

  const handleAdviceSelected = (isSelected, value) => {
    if (isSelected) {
      setAdviceSelected((prev) => [...prev, parseInt(value.id)])
    } else {
      const selectedAdvices = adviceSelected.filter((item) => item !== parseInt(value.id))
      setAdviceSelected(selectedAdvices)
    }
  }

  const handleDefaulthChecked = (item) => {
    const checked = adviceSelected.map((x) => {
      if (parseInt(item.id) === x) {
        return true
      } else {
        return false
      }
    })
    const status = checked.some((item) => item === true)
    return status
  }

  return (
    <Layout
      history={props.history}
      render={() =>
        params.isHieLoading || isLoadingData ? (
          <Container className="d-flex justify-content-center align-items-center">
            <Spin size="large" />
          </Container>
        ) : (
          <Row className="w-100 p-3 m-0" gutter={20}>
            <Col xs={24} md={8}>
              <EditorBox>
                <FloatTitleBox>
                  <FloatTitleText borderColor="#757575" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                    {'วันที่ตรวจรักษา'}
                  </FloatTitleText>
                </FloatTitleBox>
                <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${
                  healthCheckUpNote?.orderDateTime
                    ? moment(healthCheckUpNote?.orderDateTime)
                        .add(543, 'years')
                        .format('LL')
                    : '-'
                }`}</Text>
              </EditorBox>
              <EditorBox justify="center" gutter={[16, 16]}>
                <ColFlexCenter span={24}>
                  <Image width={150} src={patientInfo?.imageBase64 ? `data:image/png;base64,${patientInfo?.imageBase64}` : Avatar} style={{ borderRadius: '50%' }} />
                </ColFlexCenter>
                <ColFlexCenter span={24}>
                  <Text style={{ fontSize: '20px' }}>{`${patientInfo?.fullname?.title}${patientInfo?.fullname?.firstName} ${patientInfo?.fullname?.lastName}`}</Text>
                </ColFlexCenter>
                {patientInfo?.hn?.length > 0 && (
                  <Col span={24} className="p-0">
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'HN'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${events.convertHn(patientInfo?.hn[0]?.hn)}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'บัตรประชาชน'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.cid}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'เพศ'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.sexName}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'วันเกิด'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${
                        patientInfo?.DOB
                          ? moment(patientInfo?.DOB)
                              .add(543, 'years')
                              .format('LL')
                          : '-'
                      }`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'เบอร์โทร'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.contactInfo?.mobileNumber.length > 0 ? patientInfo?.contactInfo?.mobileNumber : '-'}`}</Text>
                    </EditorBox>
                    <EditorBox borderColor="#ededed">
                      <FloatTitleBox>
                        <FloatTitleText borderColor="#b8b8b8" backgroundColor="#f0f0f0" titleColor="dark" style={{ fontSize: '12px' }}>
                          {'ที่อยู่'}
                        </FloatTitleText>
                      </FloatTitleBox>
                      <Text style={{ fontSize: '16px', fontWeight: 'normal', textAlign: 'center', width: '100%' }}>{`${patientInfo?.address?.houseNumber} ตำบล ${patientInfo?.address?.tmbpart ?? '-'}, อำเภอ ${patientInfo?.address?.amppart ??
                        '-'}, จังหวัด ${patientInfo?.address?.chwpart ?? '-'} ${patientInfo?.address?.addressPart}`}</Text>
                    </EditorBox>
                  </Col>
                )}
              </EditorBox>
            </Col>
            <Col xs={24} md={16}>
              <Row gutter={[0, 12]} className="w-100">
                <Col flex="auto">
                  <TextEditor title="สรุปการตรวจร่างกายทั่วไป" value={summaryNote} setValue={setSummaryNote} />
                </Col>
                <Col flex="auto">
                  <TextEditor title="สรุปผลแลป" value={labNote} setValue={setLabNote} />
                  <div className="d-flex align-items-center px-3">
                    <p className="mr-2">{`สถานะผลแลป: `}</p>
                    <Radio.Group
                      defaultValue={labStatus}
                      onChange={(e) => {
                        setLabStatus(e.target.value)
                      }}
                    >
                      <Radio value={true}>
                        <p>{`ไม่ผิดปกติ`}</p>
                      </Radio>
                      <Radio value={false}>
                        <p style={{ color: 'red' }}>{`มีบางอย่างผิดปกติ`}</p>
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
                <Col flex="auto">
                  <TextEditor title="สรุปผลตรวจ X-Ray" value={xrayNote} setValue={setXrayNote} />
                </Col>
              </Row>
              <Row justify="start" className="pt-2 pb-3 px-3">
                <Col span={24}>
                  <Text style={{ fontWeight: 'bold' }}>เอกสารที่แนะนำสำหรับผู้ป่วยรายนี้</Text>
                </Col>
                {params.doctorAdvices?.length > 0 &&
                  params.doctorAdvices?.map((item, index) => (
                    <Col xs={24} sm={12} key={'adviceList' + index}>
                      <Checkbox
                        checked={handleDefaulthChecked(item)}
                        onChange={(e) => {
                          handleAdviceSelected(e.target.checked, item)
                        }}
                      >
                        {item.label}
                      </Checkbox>
                    </Col>
                  ))}
              </Row>
              <div className="text-right">
                <Popconfirm title="ยืนยันการบันทึกข้อมูล" onConfirm={() => saveHealthCheckUp()} okText="ยืนยัน" cancelText="ยกเลิก">
                  <Button type="primary" block size="large" className="my-1 w-100">
                    <p style={{ fontSize: '18px' }}>บันทึกข้อมูล</p>
                  </Button>
                </Popconfirm>
                <Button className="my-2" onClick={() => setVisibleModal(true)}>
                  ดูประวัติการแก้ไขข้อมูล
                </Button>
              </div>
            </Col>
            <CheckUpLogsModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} healthCheckUpData={healthCheckUpNote} />
          </Row>
        )
      }
    />
  )
}

export default note
