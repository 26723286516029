import { observable, action, computed, toJS } from 'mobx'
import jwtDecode from 'jwt-decode'

import http from '../../services/httpServiceEver'
import constant from '../../constant'

const apiEndpoint = '/users'
const tokenKey = 'token'

class User {
  @observable session = {
    data: null,
    error: null,
  }

  @observable info = {
    data: null,
    error: null,
  }

  @observable doctorTypes = {
    data: [],
    error: null,
  }

  @observable otherTypes = {
    data: [],
    error: null,
  }

  @action register = async (payload) => {
    try {
      const res = await http.post(`${apiEndpoint}`, { ...payload })
      return res
    } catch (error) {
      console.log('register error')
      console.log(error.response)
      return error.response.data.error.message
    }
  }
  @action verifyUserById = async (id) => {
    try {
      const res = await http.patch(`${apiEndpoint}/${id}`, { verified: true, emailVerified: true })
      return res
    } catch (error) {
      console.log('register error', error)
      return error
    }
  }

  @action fetchDoctorTypes = async () => {
    try {
      const doctorTypes = await http.get('/DoctorTypes')
      this.doctorTypes.data = doctorTypes.data
    } catch (error) {
      this.doctorTypes.error = error.response
    }
  }

  @action fetchDoctorTypeUsers = async (doctoyTypeId) => {
    try {
      const { data } = await http.get(`/DoctorTypes/${doctoyTypeId}/appUsers`)
      return data
    } catch (error) {
      return error
    }
  }

  @action fetchOtherTypes = async () => {
    try {
      const otherTypes = await http.get('/OtherTypes')
      this.otherTypes.data = otherTypes.data
    } catch (error) {
      this.otherTypes.error = error.response
    }
  }

  @action fetchOtherTypesUsers = async (otherTypeId) => {
    try {
      const { data } = await http.get(`/OtherTypes/getAppUserByOtherTypeId?otherTypeId=${otherTypeId}`)
      return data
    } catch (error) {
      return error
    }
  }
  @action login = async (email, password) => {
    try {
      const { data } = await http.post(`${apiEndpoint}/login`, { email, password })
      const jwt = data.id
      if (
        data.roles.includes('doctor') ||
        data.roles.includes('observerStaff') ||
        data.roles.includes('admin') ||
        data.roles.includes('callCenter') ||
        data.roles.includes('pharmacy') ||
        data.roles.includes('pharmacyStore') ||
        data.roles.includes('nurse') ||
        data.roles.includes('physiotherapist') ||
        data.roles.includes('speechCorrectionSpecialist') ||
        data.roles.includes('pharmacySchedule') ||
        data.roles.includes('nutritionist') ||
        data.roles.includes('bloodBankTechnician') ||
        data.roles.includes('staff')
      ) {
        localStorage.setItem(tokenKey, jwt)
        http.setJwt(jwt)
        this.session.data = jwtDecode(jwt)
        await this.fetchInfo()
        this.session.error = null
      } else {
        this.session.error = { status: 401 }
      }
    } catch (e) {
      localStorage.removeItem(tokenKey)
      http.clearJwt()
      this.session.error = e.response
    }
  }

  @action loginWithJwt = () => {
    try {
      const jwt = localStorage.getItem(tokenKey)
      http.setJwt(jwt)
      this.session.data = jwtDecode(jwt)
    } catch (e) {
      // console.error(e)
      localStorage.removeItem(tokenKey)
      http.clearJwt()
      this.session.data = null
    }
  }

  @action createLogReceiveCalling = async (data) => {
    try {
      await http.post(`${apiEndpoint}/me/Logs`, data)
    } catch (error) {
      console.log(error)
    }
  }

  @action logout = () => {
    localStorage.clear()
    http.clearJwt()
    this.session.data = null
  }

  @action setUpNotification = async () => {
    try {
      const deviceToken = localStorage.getItem(constant.PUSH_TOKEN)
      await http.post('/Installations', {
        appId: 'evervideo',
        // userId: this.info.data.id,
        deviceToken,
        deviceType: 'web',
      })
    } catch (error) {
      throw error
    }
  }

  @action fetchInfo = async () => {
    try {
      const response = await http.get(`${apiEndpoint}/me`)
      this.info.data = response.data
      return response
    } catch (e) {
      if (e.response) {
        this.info.error = e.response
        return e.response.status
      }
    }
  }

  @action fetchUserInfo = async (id) => {
    try {
      const { data } = await http.get(`${apiEndpoint}/${id}`)
      return data
    } catch (e) {
      console.log(e)
    }
  }

  @action updateProfileDetail = async (id, info) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, info)
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action updateProfileImage = async (id, profileImage) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, { profileImage })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action updateName = async (id, fullname) => {
    try {
      const { data } = await http.patch(`${apiEndpoint}/${id}`, { fullname })
      return data
    } catch (error) {
      console.log(error)
    }
  }

  @action changePassword = async (oldPassword, newPassword) => {
    const token = localStorage.getItem('token')
    try {
      const { data } = await http.post(`${apiEndpoint}/change-password`, {
        oldPassword,
        newPassword,
        headers: {
          Authorization: token,
        },
      })
      return data
    } catch (err) {
      const { response } = err
      return response.data.error
    }
  }

  @action deleteUser = async (id) => {
    try {
      const { data } = await http.delete(`${apiEndpoint}/${id}`)
      return data
    } catch (err) {
      const { response } = err
      return response.data.error
    }
  }

  @computed get getSession() {
    return toJS(this.session.data)
  }

  @computed get getDoctorTypes() {
    return toJS(this.doctorTypes.data)
  }

  @computed get getOtherTypes() {
    return toJS(this.otherTypes.data)
  }

  @computed get getInfo() {
    return toJS(this.info.data)
  }
}

export default new User()
