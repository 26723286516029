import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import RegisterForm from '../../components/RegisterForm'
import Layout from '../Home'
import { message } from 'antd'
@inject('User')
@inject('AppUserAndHospital')
@inject('AppUserAndOtherType')
@observer
class Register extends React.Component {
  state = {
    hasSubmitted: false,
    roles: [
      { name: 'แพทย์', value: 'doctor' },
      { name: 'เภสัชกร', value: 'pharmacy' },
      { name: 'คอลเซ็นเตอร์', value: 'callCenter' },
      { name: 'พยาบาล', value: 'nurse' },
      { name: 'นักกายภาพ', value: 'physiotherapist' },
      { name: 'เจ้าหน้าที่แก้ไขการพูด', value: 'speechCorrectionSpecialist' },
      { name: 'เภสัชกร (ไม่ส่งยา)', value: 'pharmacySchedule' },
      { name: 'นักโภชนาการ', value: 'nutritionist' },
      { name: 'เจ้าหน้าที่สังเกตการณ์', value: 'observerStaff' },
      { name: 'เจ้าหน้าที่ EMS', value: 'staff' },
      { name: 'ร้านยา', value: 'pharmacyStore' },
      { name: 'แพทย์แผนกตรวจสุขภาพ', value: 'healthCheckUpDoctor' },
      { name: 'เจ้าหน้าที่ห้องธนาคารเลือด', value: 'bloodBankTechnician' },
    ],
    doctorTypes: [],
    otherTypes: [],
  }

  componentDidMount() {
    this.mounted()
  }
  mounted = async () => {
    const { User } = this.props
    await User.fetchDoctorTypes()
    await User.fetchOtherTypes()
    const types = User.getDoctorTypes
    const otherTypesData = User.getOtherTypes
    this.setState({
      doctorTypes: types,
      otherTypes: otherTypesData,
    })
  }

  handleSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      gender: values.gender,
      nationality: values.nationality,
      doctorTypeId: values.doctorTypeId ? values.doctorTypeId : null,
      otherTypeId: values.otherTypeId ? values.otherTypeId : null,
      role: values.role,
      fullname: values.fullname,
      employeeId: values.employeeId,
      tel: values.tel,
      detail: values.detail,
    }
    const { User, AppUserAndHospital, AppUserAndOtherType } = this.props
    const response = await User.register(data)
    if (response && response.data) {
      const verify = await User.verifyUserById(response.data.id)
      if (verify && verify.data) {
        if (values.role === 'doctor') {
          await AppUserAndHospital.addDoctorType(response.data.id, data.doctorTypeId)
        } else if (['nurse', 'physiotherapist', 'speechCorrectionSpecialist', 'pharmacySchedule', 'nutritionist'].includes(values.role)) {
          await AppUserAndOtherType.addOtherType(response.data.id, data.otherTypeId)
        }
        message.success('ลงทะเบียน และยืนยันตนสำเร็จ')
      } else {
        message.error('ลงทะเบียนสำเร็จ ยืนยันตนไม่สำเร็จ')
      }

      this.setState({ hasSubmitted: true })
    } else if (response.includes('Email already exists')) {
      message.error(`ไม่สามารถลงทะเบียนได้ อีเมลถูกใช้งานแล้ว`)
    } else {
      message.error(`เกิดข้อผิดพลาดในการลงทะเบียน`)
    }
  }

  render() {
    const { hasSubmitted } = this.state
    return (
      <Layout
        {...this.props}
        render={(
          props, //eslint-disable-line
        ) => <RegisterForm doctorTypes={this.state.doctorTypes} otherTypes={this.state.otherTypes} onSubmit={this.handleSubmit} hasSubmitted={hasSubmitted} roles={this.state.roles} refreshDepartment={this.mounted} />}
      />
    )
  }
}

Register.propTypes = {
  User: PropTypes.object, //eslint-disable-line
  location: PropTypes.object, //eslint-disable-line
}

Register.defaultProps = {}
export default Register
