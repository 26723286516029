import React, { useState, useEffect, useCallback } from 'react'
import { FileOutlined, LoadingOutlined, LeftOutlined } from '@ant-design/icons'
import { Input, Tag, Row, Col, Modal, Select, Card, Spin, Image, message } from 'antd'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import 'moment/locale/th'
import firebase from 'firebase'
import TextBase from '../Text'
import Button from '../Button'
import { BOOKING_DOCTOR_STATUS, ROOM_STATUS, PAYMENT_STATUS, LOGISTIC, ROLES } from '../../constant'
import ModalPatientHistory from '../Modal/PatientHistory'
import UploadImage from '../UploadImage'
import ActionButtonSection from '../ActionButtonSection'
import PaymentStatus from '../PaymentStatus'
import GenQrCodePayment from '../GenQrCodePayment'
import ContentTab from './ContentTab'
import ReportProblemForm from '../ReportProblemForm'
import httpEver from '../../services/httpServiceEver'
import httpEverHealthCare from '../../services/httpServiceHealthCare'
import CancelBookingForm from '../CancelBookingForm'
import CheckHnnumber from '../CheckHnNumber'
import QRCode from 'react-qr-code'
import { PRACTITIONER_ROLES } from '../../constant'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'
import CancelBookingModal from './modals/CancelBookingModal'
import CancelBookingFormModal from './modals/CancelBookingFormModal'
import ReportProblemModal from './modals/ReportProblemModal'
import ReportProblemFormModal from './modals/ReportProblemFormModal'
import MedicalBenefitModal from './modals/MedicalBenefitModal'
import useHnConverter from '../../hooks/DataConverter/useHNConverter/useHNConverter'
import { Text, Search, QrSection, DownloadSection, ButtonSection, HeaderWrapper, Container, ChatBody, ChatBox, Send } from './style'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const { Option } = Select

const BookingTable = ({
  state,
  history,
  updateVisitNumber,
  fetchPatient,
  fetchMedicalBenefit,
  refreshData,
  setCurrentTab,
  fetchBookingGallery,
  fetchBookingRooms,
  setImages,
  fetchUserImage,
  setSounds,
  setLinks,
  fetchRecordListFromRoomId,
  fetchRecordFile,
  fetchUserFcmToken,
  setRecordList,
  checkVisitNumber,
  doctorTypes,
  otherTypes,
  checkAndRenderCompositionVideo,
  setState,
  ...rest
}) => {
  const [patient, setPatient] = useState()
  const [bookingId, setBookingId] = useState()
  const [patientId, setPatientId] = useState()
  const [patientStatus, setPatientStatus] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [visibleModalHistory, setVisibleModalHistory] = useState(false)
  const [visibleVisitNumber, setVisibleVisitNumber] = useState(false)
  const [vnNumber, setVnNumber] = useState('')
  const [visibleMedicalBenefit, setVisibleMedicalBenefit] = useState(false)
  const [record, setRecord] = useState(false)
  const [roles, setRoles] = useState(jwtDecode(localStorage.getItem('token')).roles)
  const [user, setUser] = useState(jwtDecode(localStorage.getItem('token')))
  const [filterSound, setFilterSound] = useState(false)
  const [visibleModalGAllery, setVisibleModalGallery] = useState(false)
  const [visibleModalRecordSound, setVisibleModalRecordSound] = useState(false)
  const [visibleModalRecordNote, setVisibleModalRecordNote] = useState(false)
  const [visibleChatModal, setVisibleChatModal] = useState(false)
  const [visibleReportProblemModal, setVisibleReportProblemModal] = useState(false)
  const [visibleReportProblemInfoModal, setVisibleReportProblemInfoModal] = useState(false)
  const [visibleCancelBookingModal, setVisibleCancelBookingModal] = useState(false)
  const [visibleCancelBookingInfoModal, setVisibleCancelBookingInfoModal] = useState(false)
  const [visibleChatNumber, setVisibleChatNumber] = useState(false)
  const [bookingsData, setBookingsData] = useState([])
  const [todayBookingsData, setTodayBookingsData] = useState([])
  const [futureBookingsData, setFutureBookingsData] = useState([])
  const [pastBookingsData, setPastBookingsData] = useState([])
  const [cancelBookingsData, setCancelBookingsData] = useState([])
  const [filterData, setFilterData] = useState(false)
  const [filterDataAlert, setFilterDataAlert] = useState(false)
  const [filterTodayBookingsData, setFilterTodayBookingsData] = useState(false)
  const [filterFutureBookingsData, setFilterFutureBookingsData] = useState(false)
  const [filterPastBookingsData, setFilterPastBookingsData] = useState(false)
  const [filterCancelBookingsData, setFilterCancelBookingsData] = useState(false)
  const [showLink, setShowLink] = useState(false)
  const [note, setNote] = useState(false)
  const [notes, setNotes] = useState(false)
  const [noteProblems, setNoteProblems] = useState(false)
  const [cancelBookingCause, setCancelBookingCause] = useState(false)
  const [isHistoryPage, setIsHistoryPage] = useState(false)
  const [messages, setMessages] = useState([])
  const [Usermessages, setUserMessages] = useState([])
  const [doctorId, setDoctorId] = useState(false)
  const [actionStatus, setActionStatus] = useState(false)
  const [showColumnByTab, setShowColumnByTab] = useState(false)
  const [sortByUrl, setSortByUrl] = useState(false)
  const [searchText, setSearchText] = useState(false)
  const [soundSid, setSoundSid] = useState(false)
  const { dataSource } = state
  const [loadPayment, setLoadPayment] = useState(true)
  const [isVisibleQr, setIsVisibleQr] = useState(false)
  const [patientHn, setPatientHn] = useState(false)
  const [patientVn, setPatientVn] = useState(false)
  const [amountQr, setAmountQr] = useState(false)
  const [valueQrCode, setValueQrCode] = useState(false)
  const [listVn, setListVn] = useState([])
  const [saving, savingState] = useState('')
  const [buttonGenQr, setButtonGenQr] = useState(false)

  useEffect(() => {
    const getFirebaseStatus = () => {
      setIsLoading(true)
      firebase
        .database()
        .ref('patientStatus')
        .on('value', (value) => {
          const data = []
          value.forEach((valueStatus) => {
            data.push({
              ...valueStatus.val(),
              key: valueStatus.key,
            })
          })
          setPatientStatus(data)
          setIsLoading(false)
        })
    }
    if (roles.includes('doctor') || roles.includes('pharmacy')) {
      getFirebaseStatus()
    }
  }, [])

  useEffect(() => {
    checkPath()
    clearTableData()
    const data = splitDatasourceByTimePeriod()
    setCancleBookings()
  }, [state.dataSource])


  const addValueQrCode = (price, vn, hn) => {
    setAmountQr(price)
    setValueQrCode(`|099400016602812
${patientHn || hn || 0}
${vn || 0}
${parseFloat(price || 0) * 100}`)
  }

  const handleDeclineCall = useCallback(async (event) => {
    console.log('event')
    console.log(event)
    firebase
      .database()
      .ref(`patientStatus/${event}`)
      .update({ status: 'completed', date: '' })
  }, [])

  const checkPath = () => {
    history.location.pathname.includes('history-booking') ? setIsHistoryPage(true) : setIsHistoryPage(false)
    const key = history?.location?.state?.key
    let sortedData = state.dataSource

    if (['patientDraft', 'ephisPending', 'phamacyPendingBookings', 'patientEms', 'patientPendingPayment'].includes(key)) {
      setSortByUrl(true)
    } else {
      setSortByUrl(false)
    }
    if (roles.includes('pharmacy')) {
      sortedData = sortedData.sort((a, b) => {
        return b.bookingTime - a.bookingTime
      })
    }
    if (key === 'patientDraft') {
      sortedData = sortDataByStatus('PATIENT_DRAFT')
    } else if (key === 'ephisPending') {
      sortedData = sortDataByStatus('EPHIS_PENDING')
    } else if (key === 'phamacyPendingBookings') {
      sortedData = sortDataByStatus('PHARMACY_PENDING_BOOKING')
    } else if (key === 'patientEms') {
      sortedData = sortDataByPrescriptionStatus(['WAIT_FOR_PATIENT_PHARMACY', 'WAIT_FOR_PATIENT_EMS', 'WAIT_FOR_PATIENT_PHAMACYSTORE'])
    } else if (key === 'patientPendingPayment') {
      sortedData = sortByPaymentStatus('PATIENT_PENDING_PAYMENT')
    } else if (key === 'alert') {
      setCurrentTab('alert')
    }
    setBookingsData(sortedData)
  }

  const sortDataByStatus = (status) => {
    return state.dataSource.sort((a, b) => {
      if ((a.status.includes(status) && b.status.includes(status)) || (!a.status.includes(status) && !b.status.includes(status))) {
        return 0
      } else if (!a.status.includes(status) && b.status.includes(status)) {
        return 1
      } else if (a.status.includes(status) && !b.status.includes(status)) {
        return -1
      }
    })
  }

  const sortDataByPrescriptionStatus = (status) => {
    let data = sortPrescription(state.dataSource, 'status')
    return data.sort((a, b) => {
      if (a.prescription && b.prescription) {
        if ((status.includes(a.prescription.status) && status.includes(b.prescription.status)) || (!status.includes(a.prescription.status) && !status.includes(b.prescription.status))) {
          return 0
        } else if (!status.includes(a.prescription.status) && status.includes(b.prescription.status)) {
          return 1
        } else if (status.includes(a.prescription.status) && !status.includes(b.prescription.status)) {
          return -1
        }
      }
    })
  }

  const sortByPaymentStatus = (status) => {
    let data = sortPrescription(state.dataSource, 'paymentStatus')
    return data.sort((a, b) => {
      if (a.prescription && b.prescription && a.prescription.paymentStatus && b.prescription.paymentStatus) {
        if ((a.prescription.paymentStatus.includes(status) && b.prescription.paymentStatus.includes(status)) || (!a.prescription.paymentStatus.includes(status) && !b.prescription.paymentStatus.includes(status))) {
          return 0
        } else if (!a.prescription.paymentStatus.includes(status) && b.prescription.paymentStatus.includes(status)) {
          return 1
        } else if (a.prescription.paymentStatus.includes(status) && !b.prescription.paymentStatus.includes(status)) {
          return -1
        }
      }
    })
  }

  const sortPrescription = (dataSource, type) => {
    let data = dataSource.sort((a, b) => {
      if (a.prescription && b.prescription) {
        return 0
      } else if (!a.prescription && b.prescription) {
        return 1
      } else if (a.prescription && !b.prescription) {
        return -1
      }
    })
    if (type === 'status') {
      return data
    } else if (type === 'paymentStatus') {
      data = data.sort((a, b) => {
        if (a.prescription && b.prescription) {
          if (a.prescription.paymentStatus && b.prescription.paymentStatus) {
            return 0
          } else if (!a.prescription.paymentStatus && b.prescription.paymentStatus) {
            return 1
          } else if (a.prescription.paymentStatus && !b.prescription.paymentStatus) {
            return -1
          }
        }
      })
      return data
    }
  }

  const clearTableData = () => {
    setPastBookingsData([])
    setFutureBookingsData([])
    setTodayBookingsData([])
    setCancelBookingsData([])
  }

  const splitDatasourceByTimePeriod = () => {
    let today = moment()
    today = today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    let dataFiltered = {
      pastBookingsData: [],
      futureBookingsData: [],
      todayBookingsData: [],
    }
    dataSource.map((data) => {
      let admitTime = moment(data.admitTime)
      admitTime = admitTime.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      const checkDateDiff = today.diff(admitTime, 'days')

      if (checkDateDiff > 0) {
        // Push data for Past Booking Section
        setPastBookingsData((pastBookingsData) => [...pastBookingsData, data])
        dataFiltered.pastBookingsData.push(data)
      } else if (checkDateDiff < 0) {
        // Push data for Future Booking Section
        setFutureBookingsData((futureBookingsData) => [...futureBookingsData, data])
        dataFiltered.futureBookingsData.push(data)
      } else {
        // Push data for Future Booking Section
        setTodayBookingsData((todayBookingsData) => [...todayBookingsData, data])
        dataFiltered.todayBookingsData.push(data)
      }
    })
    return dataFiltered
  }

  const setCancleBookings = () => {
    setCancelBookingsData(state.dataCancelBookings)
  }

  const getStatus = (id) => {
    let findPatientStatus
    if (roles.includes('doctor')) {
      findPatientStatus = patientStatus.find((value) => value.key == id)
    } else {
      findPatientStatus = patientStatus.find((value) => value.key.split('pharmacy')[1] == id)
    }
    if (findPatientStatus && findPatientStatus.status) {
      return (
        <Tag color={findPatientStatus.status === 'exitedWaitingRoom' ? 'red' : 'green'}>
          {ROOM_STATUS[findPatientStatus.status] === 'ผู้ป่วยพบแพทย์เสร็จสิ้น' ? 'ผู้ป่วย Call เสร็จสิ้น' : ROOM_STATUS[findPatientStatus.status] === 'ผู้ป่วยกำลังพบแพทย์' ? 'ผู้ป่วยกำลัง Call' : ROOM_STATUS[findPatientStatus.status]}
        </Tag>
      )
    }
    return <div />
  }


  const sortNear = (time, booking) => {
    const momentTime = moment()
      .startOf('day')
      .add(booking, 'minutes')
      .format('HH:mm')
    const momentDate = moment(time).format('MM/DD/YYYY')
    const momentDateTime = `${momentDate} ${momentTime}`
    const date = moment(momentDateTime).fromNow(moment().format('lll'))
    const value = date.split(' ')[0]
    const unit = date.split(' ')[1]
    let data
    if (unit === 'นาที') {
      data = value * 60
    } else if (unit === 'ชั่วโมง') {
      data = value * 60 * 60
    } else if (unit === 'วัน') {
      data = value * 60 * 60 * 24
    } else if (unit === 'เดือน') {
      data = value * 60 * 60 * 24 * 30
    } else if (unit === 'ปี') {
      data = value * 60 * 60 * 24 * 30 * 12
    }
    return data
  }
  const checkFromNow = (time, booking) => {
    const momentTime = moment()
      .startOf('day')
      .add(booking, 'minutes')
      .format('HH:mm')
    const momentDate = moment(time).format('MM/DD/YYYY')
    const momentDateTime = `${momentDate} ${momentTime}`
    const date = moment(momentDateTime).fromNow(moment().format('lll'))
    return date
  }
  const setColor = (status, key) => {
    if (!status) {
      return 'blue'
    }
    if (status.includes('PENDING') || status.includes('DRAFT')) {
      return 'orange'
    }
    if (status.includes('CONFIRM') || status.includes('PAID') || status.includes('COMPLETED') || status.includes('SUCCESS')) {
      return 'green'
    }
    if (status.includes('DECLINE') || status.includes('CANCEL') || status.includes('ALERT')) {
      return 'red'
    }
    return 'blue'
  }

  const fetchChat = async (patientId) => {
    const { } = process.env
    const firestore = firebase.firestore()
    let firebaseDocument
    const doctorId = user.roles.includes('pharmacy') ? 0 : user.id
    const messagesChat = []
    if (doctorId === 0) {
      firebaseDocument = `patient${patientId}pharmacy`
    } else {
      firebaseDocument = `patient${patientId}${user.roles[0]}${doctorId}`
    }
    const messagesRef = firestore
      .collection('THREADS_production')
      .doc(firebaseDocument)
      .collection('MESSAGES')
    const query = messagesRef.orderBy('createdAt').limit(25)
    await query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        messagesChat.push(doc.data())
      })
    })
    setDoctorId(user.id)
    setMessages(messagesChat)
  }

  const fetchChatCallcenter = async (record) => {
    const firestore = firebase.firestore()
    let firebaseDocument
    const messagesChat = []
    const patientId = record.patientId
    const doctorId = record.doctorId
    const DoctorRoles = record.doctor.roles[0].name
    firebaseDocument = `patient${patientId}${DoctorRoles}${doctorId}`
    console.log(`patient${patientId}${DoctorRoles}${doctorId}`)
    const messagesRef = firestore
      .collection('THREADS_production')
      .doc(firebaseDocument)
      .collection('MESSAGES')
    const query = messagesRef.orderBy('createdAt')
    await query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        messagesChat.push(doc.data())
      })
    })
    // console.log('chat', messagesChat)
    if (messagesChat) {
      const chatnumber = messagesChat.filter((e) => {
        if (e.user._id == patientId) {
          return e
        }
      })
      setUserMessages(chatnumber)
    }
  }
  const CheckDepartment = (doctor) => {
    let types = []
    let id
    if (doctor?.doctorTypeId) {
      types = doctorTypes
      id = doctor.doctorTypeId
    } else if (doctor?.otherTypeId) {
      types = otherTypes
      id = doctor.otherTypeId
    }
    if (!types) {
      return doctor.detail?.department
    }
    const department = types.length ? types.find((element) => element.id == id) : {}
    return department?.name ?? '-'
  }

  const ChatMessage = (chat) => {
    const { text, image, user, read, photoURL } = chat.message
    const classMessage = user._id === Number(chat.doctorId) ? 'send' : 'received'
    return (
      <div className={`message ${classMessage}`}>
        <img src={photoURL || ''} alt="" />
        <ChatBox>
          <Send>{classMessage === 'send' ? 'คุณ' : 'ผู้ป่วย'}</Send>
          {image ? <img style={{ width: '200px', height: '200px' }} src={image} alt="" /> : <Text>{text}</Text>}
        </ChatBox>
      </div>
    )
  }

  let columns
  let historyColumns = [
    {
      title: 'Chat',
      key: 'chat',
      render: (text) => (
        <Button
          type="primary"
          onClick={() => {
            fetchChat(text.patientId)
            setVisibleChatModal(true)
          }}
        >
          Chat
        </Button>
      ),
      width: 100,
      align: 'center',
    },
    {
      title: 'รูปภาพ',
      key: 'gallery',
      render: (text) => (
        <Button
          type="primary"
          onClick={() => {
            setBookingId(text.id)
            fetchBookingGallery(text.id)
            setVisibleModalGallery(true)
          }}
        >
          เช็ครูปภาพ
        </Button>
      ),
      width: 100,
      align: 'center',
    },
    {
      title: 'ไฟล์วิดีโอ',
      key: 'record',
      align: 'center',
      width: 100,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            fetchBookingRooms(record.id)
            setVisibleModalRecordSound(true)
          }}
        >
          ไฟล์วิดีโอ
        </Button>
      ),
    },
    {
      title: 'Note',
      key: 'note',
      align: 'center',
      width: 100,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            setNotes(record.notes)
            setVisibleModalRecordNote(true)
          }}
        >
          Note
        </Button>
      ),
    },
  ]
  const ButtonAction = (record) => {
    return (
      <ActionButtonSection
        roleAction={record.doctor && record.doctor.roles && record.doctor.roles.length > 0 && (ROLES[record.doctor.roles[0]['name']] || 'แพทย์')}
        history={history}
        booking={record}
        refreshData={refreshData}
        setVisibleReportProblemModal={(status) => setVisibleReportProblemModal(status)}
        setVisibleCancelBookingModal={(status) => setVisibleCancelBookingModal(status)}
        setBookingId={(bookingId) => setBookingId(bookingId)}
        setPatientId={(patientId) => setPatientId(patientId)}
        setActionStatus={(status) => setActionStatus(status)}
        clearReportProblems={(bookingId) => clearReportProblems(bookingId)}
        currentTab={state.currentTab}
        handleDeclineCall={handleDeclineCall}
      ></ActionButtonSection>
    )
  }
  if (PRACTITIONER_ROLES.includes(roles[0])) {
    columns = [
      {
        title: 'ชื่อผู้ป่วย',
        key: 'patientName',
        dataIndex: 'patient',
        render: (text) =>
          text && (
            <div>
              <Text>{text.fullnameHIE ? text.fullnameHIE : text.fullname}</Text>
              <Text>{!text.fullnameHIE && '(ชื่อจาก App)'}</Text>
            </div>
          ),
        align: 'center',
        width: 200,
      },
      {
        key: 'history',
        align: 'center',
        width: 100,
        render: (record) => (
          <Button
            type="primary"
            onClick={() => {
              setVisibleModalHistory(true)
              setRecord(record)
              fetchPatient(record.patientId)
              fetchUserImage(record.patientId)
            }}
          >
            ประวัติ
          </Button>
        ),
      },
      {
        title: 'วันที่',
        key: 'date',
        width: 150,
        defaultSortOrder: 'descend',
        render: (record) => (
          <Text
            onClick={() => {
              console.log(record)
            }}
          >
            {moment(record.admitTime).format('DD/MM/YYYY')}
          </Text>
        ),
        align: 'center',
        sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
      },
      {
        title: 'เวลา',
        key: 'time',
        render: (record) =>
          roles.includes('doctor') ? (
            <Text>
              {moment()
                .startOf('day')
                .add(record.bookingTime, 'minutes')
                .format('HH:mm')}
            </Text>
          ) : (
            <Text>
              {moment()
                .startOf('day')
                .add(record.bookingTime, 'minutes')
                .format('HH:mm')}
            </Text>
          ),
        align: 'center',
      },
      {
        title: 'สถานะ',
        key: 'status',
        width: 150,
        align: 'center',
        render: (record) => <Tag color={setColor(record.status)}>{BOOKING_DOCTOR_STATUS[record.status]}</Tag>,
      },
      {
        title: 'วิธีการจัดส่ง',
        key: 'logisticId',
        dataIndex: 'prescription',
        width: 150,
        align: 'center',
        render: (text) => (text && text.logisticId ? <Text>{LOGISTIC[text.logisticId]}</Text> : null),
      },
      {
        title: 'คำสั่ง',
        key: 'action',
        align: 'center',
        render: (record) => ButtonAction(record),
      },
    ]
    if (isHistoryPage) {
      columns = [...columns, ...historyColumns]
    }
    columns = [
      ...columns,
      {
        title: 'สถานะของผู้ป่วย',
        key: 'patient_status',
        align: 'center',
        render: (record) => (BOOKING_DOCTOR_STATUS[record.status] === 'แพทย์ทำการอนุมัติ' ? getStatus(record.patientId) : null),
      },
    ]
  } else if (roles.includes('pharmacy')) {
    columns = [
      {
        title: 'ชื่อผู้ป่วย',
        key: 'patientName',
        dataIndex: 'patient',
        render: (text) =>
          text && (
            <div>
              <Text>{text.fullnameHIE ? text.fullnameHIE : text.fullname}</Text>
              <Text>{!text.fullnameHIE && '(ชื่อจาก App)'}</Text>
            </div>
          ),
        width: 200,
        align: 'center',
      },
      {
        title: 'HN',
        key: 'hnId',
        align: 'center',
        width: 100,
        render: (record) => (
          <Text>
            <CheckHnnumber key={record} data={record} loading={<Spin />} />
          </Text>
        ),
      },
      {
        title: 'วันที่',
        key: 'date',
        width: 100,
        defaultSortOrder: sortByUrl ? null : 'descend',
        render: (record) => (
          <Text
            onClick={() => {
              console.log(record)
            }}
          >
            {moment(record.admitTime).format('DD/MM/YYYY')}
          </Text>
        ),
        sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
        align: 'center',
      },
      {
        title: 'เวลานัดหมาย',
        key: 'time',
        width: 100,
        render: (record) => (
          <Text>
            {moment()
              .startOf('day')
              .add(record.bookingTime, 'minutes')
              .format('HH:mm')}
            <span> - </span>
            {moment()
              .startOf('day')
              .add(record.bookingEndTime, 'minutes')
              .format('HH:mm')}
          </Text>
        ),
        align: 'center',
      },
      {
        title: 'สถานะ',
        key: 'status',
        width: 200,
        render: (record) => <Tag color={setColor(record.status)}>{BOOKING_DOCTOR_STATUS[record.status]}</Tag>,
        align: 'center',
      },
      {
        title: 'คำสั่ง',
        key: 'action',
        width: isHistoryPage ? 80 : 250,
        render: (record) => ButtonAction(record),
      },
    ]
    if (isHistoryPage) {
      columns = [...columns, ...historyColumns]
    }
    // columns = [
    //   ...columns,
    //   {
    //     title: 'สถานะของผู้ป่วย',
    //     key: 'patient_status',
    //     width: 120,
    //     align: 'center',
    //     render: (record) => (BOOKING_DOCTOR_STATUS[record.status] === 'เภสัชทำการอนุมัติการจองนัด' ? getStatus(record.patientId) : null)
    //   }
    // ]
  } else if (roles.includes('callCenter')) {
    columns = [
      {
        title: 'ชื่อผู้ป่วย',
        key: 'patientName',
        render: (record) =>
          record.patient && (
            <div
              onClick={() => {
                setVisibleModalHistory(record.patientId)
                setRecord(record)
                fetchPatient(record.patientId)
                fetchUserImage(record.patientId)
              }}
              style={{ cursor: 'pointer' }}
            >
              <Text>{record.patient.fullnameHIE ? record.patient.fullnameHIE : record.patient.fullname}</Text>
              <Text>{!record.patient.fullnameHIE && '(ชื่อจาก App)'}</Text>
            </div>
          ),
        align: 'center',
      },
      {
        title: 'นับจากตอนนี้',
        key: 'near',
        render: (record) => <Text>{checkFromNow(record.admitTime, record.bookingTime)}</Text>,
        align: 'center',
        sorter: (a, b) => sortNear(b.admitTime, b.bookingTime) - sortNear(a.admitTime, a.bookingTime),
      },
      {
        title: 'วันที่',
        key: 'date',
        sorter: (a, b) => moment(a.admitTime).unix() - moment(b.admitTime).unix(),
        defaultSortOrder: sortByUrl ? null : 'descend',
        render: (record) => (
          <Text
            onClick={() => {
              console.log(record)
            }}
          >
            {moment(record.admitTime).format('DD/MM/YYYY')}
          </Text>
        ),
        align: 'center',
      },
      {
        title: 'เวลา',
        key: 'time',
        render: (record) => (
          <Text>
            {moment()
              .startOf('day')
              .add(record.bookingTime, 'minutes')
              .format('HH:mm')}
          </Text>
        ),
        align: 'center',
      },
      {
        title: 'ดูแลโดย',
        key: 'consultWith',
        align: 'center',
        render: (record) => (
          <Row>
            <Col span={24}>{record.doctor && record.doctor.roles && record.doctor.roles.length > 0 && (ROLES[record.doctor.roles[0]['name']] || 'แพทย์')}</Col>
            <Col span={24}>{record.status.includes('PHARMACY_ALERT') ? null : record.doctor && record.doctor.fullname && record.doctor.fullname}</Col>
            <Col span={24}>แผนก : {CheckDepartment(record.doctor)}</Col>
          </Row>
        ),
      },
      {
        title: 'วิธีการจัดส่ง',
        key: 'logisticId',
        dataIndex: 'prescription',
        render: (text) => (text ? <Text>{LOGISTIC[text.logisticId]}</Text> : null),
      },
      {
        title: 'เช็คสิทธิ์',
        key: 'medicalBenefit',
        render: (record) => (
          <Button
            onClick={() => {
              fetchMedicalBenefit(record.patientId)
              setPatient(record.patient)
              setVisibleMedicalBenefit(true)
            }}
          >
            สิทธิ์
          </Button>
        ),
      },
      {
        title: 'จำนวนการแชท',
        key: 'chat',
        width: 150,
        render: (record) => (
          <Button
            type="primary"
            onClick={() => {
              fetchChatCallcenter(record)
              setVisibleChatNumber(true)
            }}
          >
            แชท
          </Button>
        ),
      },
      {
        title: 'คำสั่ง',
        key: 'action',
        width: 250,
        sorter: (a, b) => {
          let statusA = (a.prescription && a.prescription.paymentStatus && a.prescription.paymentStatus.includes('PATIENT_PENDING_PAYMENT')) || ['PATIENT_DRAFT', 'DOCTOR_PENDING'].includes(a.status) ? true : false
          let statusB = (b.prescription && b.prescription.paymentStatus && b.prescription.paymentStatus.includes('PATIENT_PENDING_PAYMENT')) || ['PATIENT_DRAFT', 'DOCTOR_PENDING'].includes(b.status) ? true : false
          if ((statusA && statusB) || (!statusA && !statusB)) {
            return 0
          } else if (statusA && !statusB) {
            return -1
          } else if (!statusA && statusB) {
            return 1
          }
        },
        render: (record) => ButtonAction(record),
      },
      {
        title: 'นัดเข้ามาเมื่อ',
        key: 'createAt',
        align: 'center',
        render: (text) => (text ? <Text>{moment(text.timestamp).format('DD/MM/YYYY - HH:mm')}</Text> : null),
      },
      {
        title: 'โทรเวชกรรมล่าสุดเมื่อ',
        key: 'statusPayment',
        align: 'center',
        render: (record) => (record.lastCallAt ? <Text>{moment(record.lastCallAt).format('DD/MM/YYYY - HH:mm')}</Text> : null),
      },
      {
        title: 'สถานะการชำระเงิน',
        key: 'statusPayment',
        align: 'center',
        width: 150,
        render: (record) => <PaymentStatus key={record} data={record} loading={<Spin />} />,
      },
      {
        title: 'ข้อมูลอุปกรณ์แพทย์',
        key: 'verified',
        align: 'left',
        render: (record) =>
          record.doctorDevice ? (
            <>
              <Text>
                <Tag color="blue">{record.doctorDevice.version}</Tag>
              </Text>
              <Text>
                <Tag color="blue">{record.doctorDevice.manufacturer}</Tag>
              </Text>
              <Text>
                <Tag color="blue">{record.doctorDevice.model}</Tag>
              </Text>
            </>
          ) : (
            <Text> ยังไม่ระบุข้อมูล</Text>
          ),
      },
      {
        title: 'สร้าง QR Code',
        key: 'qrCodePayment',
        align: 'center',
        width: 150,
        render: (record) => <GenQrCodePayment record={record} loading={<Spin />} setIsVisibleQr={setIsVisibleQr} setPatientHn={setPatientHn} setPatientVn={setPatientVn} addValueQrCode={addValueQrCode} setListVn={setListVn} />,
      },
    ]
  }

  // if( state.currentTab === 'todayBookings'){
  //   columns.push({
  //     title: 'สร้าง QR Code',
  //     key: 'qrCodePayment',
  //     align: 'center',
  //     width: 150,
  //     render: (record) => (
  //       <GenQrCodePayment
  //         record={record}
  //         loading={<Spin />}
  //         setIsVisibleQr={setIsVisibleQr}
  //         setPatientHn={setPatientHn}
  //         setPatientVn={setPatientVn}
  //         addValueQrCode={addValueQrCode}
  //         setListVn={setListVn}
  //       />
  //     )
  //   })
  // }

  if (isHistoryPage && ['cancelBookings', 'alert'].includes(state.currentTab)) {
    //delete columns when show Cancel and Alert Tabs
    columns.splice(5, 9)
  }
  const columnsCancleBooking = [
    ...columns,
    {
      title: 'สาเหตุที่ยกเลิก',
      key: 'cause',
      width: 100,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            const cancleBookingCause = record.cancelBookingCause ? record.cancelBookingCause.cause : 'ไม่พบข้อมูล'
            setCancelBookingCause(cancleBookingCause)
            setVisibleCancelBookingInfoModal(true)
          }}
        >
          สาเหตุ
        </Button>
      ),
    },
  ]
  const columnsAlert = [
    ...columns,
    {
      title: 'ปัญหา',
      key: 'problems',
      width: 100,
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            const problems = record.noteProblems ? record.noteProblems.problems : '-'
            setNoteProblems(problems)
            setVisibleReportProblemInfoModal(true)
          }}
        >
          ปัญหา
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (state.currentTab == 'alert') {
      setShowColumnByTab(state.currentTab)
    } else if (state.currentTab == 'cancelBookings') {
      setShowColumnByTab(state.currentTab)
    } else {
      setShowColumnByTab(false)
    }
  }, [state.currentTab])

  const onSearch = (text, dataToUpdate) => {
    const bookingsDataForSearch = filterDataByTabForSearch(dataToUpdate);

    const { revertHN } = useHnConverter(text);

    const dataFiltered = bookingsDataForSearch.filter((data) => {
      const patient = data?.patient;
      if (patient) {
        const { cId, hnId, fullname, fullnameHIE } = patient;
        return (
          (cId?.includes(text) ?? false) ||
          (hnId?.includes(revertHN()) ?? false) ||
          (fullname?.includes(text) ?? false) ||
          (fullnameHIE?.includes(text) ?? false)
        );
      }
      return false;

    });

    setSearchText(text.length ? text : false)
    setDataByTab(text.length ? dataFiltered : false)
  };


  const filterDataByTabForSearch = (dataToUpdate) => {
    switch (state.currentTab) {
      case 'todayBookings':
        return dataToUpdate.todayBookingsData ? dataToUpdate.todayBookingsData : todayBookingsData
      case 'futureBookings':
        return dataToUpdate.futureBookingsData ? dataToUpdate.futureBookingsData : futureBookingsData
      case 'pastBookings':
        return dataToUpdate.pastBookingsData ? dataToUpdate.pastBookingsData : pastBookingsData
      case 'cancelBookings':
        return state.dataCancelBookings
      case 'alert':
        return state.dataAlert
      default:
        return state.dataSource
    }
  }

  const setDataByTab = (dataFiltered) => {
    switch (state.currentTab) {
      case 'todayBookings':
        setFilterTodayBookingsData(dataFiltered)
        break
      case 'futureBookings':
        setFilterFutureBookingsData(dataFiltered)
        break
      case 'pastBookings':
        setFilterPastBookingsData(dataFiltered)
        break
      case 'cancelBookings':
        setFilterCancelBookingsData(dataFiltered)
        break
      case 'alert':
        setFilterDataAlert(dataFiltered)
        break
      default:
        setFilterData(dataFiltered)
        break
    }
  }

  const submitReportProblemForm = async (value) => {
    const res = await httpEver.patch(`/Bookings/${bookingId}`, {
      noteProblems: value,
      status: actionStatus,
    })
    if (res.status === 200) {
      message.success('ส่งเรื่องไปยังคอลเซ็นเตอร์เรียบร้อยแล้ว')
    }
    setVisibleReportProblemModal(false)
    refreshData()
  }

  const submitCancleBookingForm = async (value) => {
    const res = await httpEver.patch(`/Bookings/${bookingId}`, {
      cancelBookingCause: value,
      status: actionStatus,
    })
    if (res.status === 200) {
      let textNoti = ''
      switch (state.user.roles[0]) {
        case 'pharmacy':
          textNoti = 'เภสัชกรได้ทำการยกเลิกการนัดหมายของคุณ ต้องขออภัยในความไม่สะดวกและให้คุณนัดเภสัชกรเข้ามาอีกครั้ง'
          break
        case 'callCenter':
          textNoti = 'เจ้าหน้าที่ยกเลิกการนัดหมาย'
          break
        default:
          textNoti = 'เจ้าหน้าที่ยกเลิกการนัดหมาย'
          break
      }
      fetchUserFcmToken(patientId, textNoti, null, 'MyBookingsUI')
      message.success('ยกเลิกนัดหมายเรียบร้อยแล้ว')
    }
    setVisibleCancelBookingModal(false)
    refreshData()
  }

  const clearReportProblems = async (id) => {
    const res = await httpEver.patch(`/Bookings/${id}`, {
      noteProblems: {},
    })
    setVisibleReportProblemModal(false)
    refreshData()
  }

  const downloadQRCode = () => {
    const canvas = document.createElement('canvas')
    const svg = document.querySelector('#qr-gen')
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)))
    const w = parseInt(svg.getAttribute('width'))
    const h = parseInt(svg.getAttribute('height'))
    const img_to_download = document.createElement('img')
    img_to_download.src = 'data:image/svg+xml;base64,' + base64doc
    // console.log(w, h);
    img_to_download.onload = function () {
      // console.log('img loaded');
      canvas.setAttribute('width', w)
      canvas.setAttribute('height', h)
      const context = canvas.getContext('2d')
      context.beginPath()
      context.fillStyle = 'white'
      context.fillRect(0, 0, 1200, 1200)

      //context.clearRect(0, 0, w, h);
      context.drawImage(img_to_download, 0, 0, w, h)
      const dataURL = canvas.toDataURL('image/png')
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), `${patientHn + patientVn}.png`)
        e.preventDefault()
      } else {
        const a = document.createElement('a')
        const my_evt = new MouseEvent('click')
        a.download = `${patientHn + patientVn}.png`
        a.href = dataURL
        a.dispatchEvent(my_evt)
      }
    }
  }

  const ContentTableTab = useCallback(
    ({
      showColumnByTab,
      state,
      columnsAlert,
      columnsCancleBooking,
      setCurrentTab,
      filterData,
      bookingsData,
      todayBookingsData,
      futureBookingsData,
      pastBookingsData,
      cancelBookingsData,
      filterTodayBookingsData,
      filterFutureBookingsData,
      filterPastBookingsData,
      filterCancelBookingsData,
      filterDataAlert,
      ...rest
    }) => {
      return (
        <ContentTab
          columns={!showColumnByTab ? columns : showColumnByTab.includes('alert') ? columnsAlert : showColumnByTab.includes('cancelBookings') && columnsCancleBooking}
          role={state?.user?.roles?.length > 0 ? state?.user?.roles[0] : ''}
          state={state}
          setTab={(tab) => setCurrentTab(tab)}
          loading={state.isLoading}
          data={{
            bookings: filterData || bookingsData,
            todayBookings: filterTodayBookingsData || todayBookingsData,
            futureBookings: filterFutureBookingsData || futureBookingsData,
            pastBookings: filterPastBookingsData || pastBookingsData,
            cancelBookings: filterCancelBookingsData || cancelBookingsData,
            alert: filterDataAlert || state.dataAlert,
          }}
          {...rest}
        />
      )
    },
    [bookingsData, state.currentTab],
  )

  const propsTab = {
    showColumnByTab,
    state,
    columnsAlert,
    columnsCancleBooking,
    setCurrentTab,
    filterData,
    bookingsData,
    todayBookingsData,
    futureBookingsData,
    pastBookingsData,
    cancelBookingsData,
    filterTodayBookingsData,
    filterFutureBookingsData,
    filterPastBookingsData,
    filterCancelBookingsData,
    filterDataAlert,
    ...rest,
  }
  return (
    <Container>
      <HeaderWrapper>
        <FileOutlined />
        <Text bold>การนัดหมาย</Text>
        {state.user && state.user.roles && state.user.roles.includes('callCenter') ? <Text> ({state.time})</Text> : null}
        <Button className="ml-2" onClick={() => refreshData()}>
          รีโหลด
        </Button>
        <Button type='primary' className="ml-2" onClick={() => { setButtonGenQr(true) }} >
          Gen QR
        </Button>
        <Modal
          visible={buttonGenQr}
          title={
            <Row gutter={24}>
              <Col style={{ marginTop: 3 }}>Generate QR Code Payment</Col>
            </Row>
          }
          footer={null}
          onCancel={() => {
            setButtonGenQr(false)
          }}
        >
          <style>{`
              .qr-code-section {
                  border: 3px solid;
                  padding: 10px;
                  border-radius: 20px;
              }
            `}</style>
          {(amountQr || (amountQr == 0 && patientVn && patientHn && valueQrCode)) && (
            <>
              <Spin indicator={antIcon} spinning={state.isLoading} tip="กรุณารอสักครู่...">
                <QrSection style={{}} className="pb-3 text-center">
                  <QRCode id="qr-gen" className="qr-code-section bg-white" size={1024} style={{ height: 'auto', maxWidth: '50%', width: '50%' }} value={valueQrCode} level={'M'} viewBox={`-30 -30 1100 1100`} />
                </QrSection>
                <DownloadSection>
                  คลิกเพื่อ{' '}
                  <ButtonSection type="button" onClick={downloadQRCode}>
                    Download QR Code
                  </ButtonSection>
                </DownloadSection>
              </Spin>
            </>
          )}
          <Row>
            <Col className="m-auto" span={5}>
              HN :
            </Col>
            <Col span={19}>
              <Input
                onChange={(e) => {
                  setPatientHn(e.target.value)
                  addValueQrCode(amountQr, patientVn, e.target.value)
                }}
                value={patientHn}
                type="number"
                min={0}
                size="medium"
                style={{ width: '50%' }}
              />
            </Col>
            <Col className="m-auto" span={5}>
              VN :
            </Col>
            <Col span={19}>
              <Input
                onChange={(e) => {
                  setPatientVn(e.target.value)
                  addValueQrCode(amountQr, e.target.value, patientHn)
                }}
                value={patientVn}
                type="number"
                min={0}
                size="medium"
                style={{ width: '50%' }}
              />
            </Col>
            <Col className="mb-auto mt-auto" span={5}>
              จำนวน :
            </Col>
            <Col span={14}>
              <Input
                onChange={(e) => {
                  addValueQrCode(e.target.value, patientVn, patientHn)
                }}
                type="number"
                min={0}
                size="medium"
                value={amountQr}
                style={{ width: '50%' }}
              />
              {'  '}บาท
            </Col>
          </Row>
        </Modal>

        <Search onSearch={onSearch} style={{ width: 200, marginLeft: 'auto' }} />
      </HeaderWrapper>
      <ContentTableTab {...propsTab} />
      <Modal
        visible={state.visibleVisitNumber}
        title="หมายเลขวิสิต"
        onOk={() => setVisibleVisitNumber(false)}
        onCancel={() => setVisibleVisitNumber(false)}
        footer={
          <div>
            <Button
              onClick={() => {
                setVisibleVisitNumber(false)
              }}
              type="danger"
            >
              ยกเลิก
            </Button>
            <Button
              type="primary"
              onClick={() => {
                updateVisitNumber(bookingId, vnNumber)
                setVisibleVisitNumber(false)
              }}
            >
              บันทึก
            </Button>
          </div>
        }
      >
        {state.visitNumber ? (
          <Row>
            <Col span={6}>
              <p>เลือกเลขวิสิต</p>
            </Col>
            <Col span={18}>
              <Select defaultValue={vnNumber} style={{ width: 120 }} onChange={(vn) => setVnNumber(vn)}>
                {state.visitNumber.map(visitNumber, (index) => (
                  <Option key={visitNumber.vn + index} value={visitNumber.vn}>
                    {visitNumber.vn}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        ) : (
          'ไม่พบเลขวิสิต'
        )}
      </Modal>

      <ModalPatientHistory isLoading={state.isLoading} hieData={state.hieData} patient={state.patientData} visible={visibleModalHistory} image={state.image} setVisible={(visible) => setVisibleModalHistory(visible)} />

      {!state.isLoading ? (
        <UploadImage
          visible={visibleModalGAllery}
          setVisible={(condition) => {
            setVisibleModalGallery(condition)
            setImages()
          }}
          bookingId={bookingId}
          images={state.images}
        />
      ) : (
        ''
      )}
      <Modal
        visible={isVisibleQr}
        title={
          <Row gutter={24}>
            <Col style={{ marginTop: 3 }}>Generate QR Code Payment</Col>
          </Row>
        }
        footer={null}
        onCancel={() => {
          setIsVisibleQr(false)
        }}
      >
        <style>{`
              .qr-code-section {
                  border: 3px solid;
                  padding: 10px;
                  border-radius: 20px;
              }
            `}</style>
        {(amountQr || (amountQr == 0 && patientVn && patientHn && valueQrCode)) && (
          <>
            <Spin indicator={antIcon} spinning={state.isLoading} tip="กรุณารอสักครู่...">
              <QrSection style={{}} className="pb-3 text-center">
                <QRCode id="qr-gen" className="qr-code-section bg-white" size={1024} style={{ height: 'auto', maxWidth: '50%', width: '50%' }} value={valueQrCode} level={'M'} viewBox={`-30 -30 1100 1100`} />
              </QrSection>
              <DownloadSection>
                คลิกเพื่อ{' '}
                <ButtonSection type="button" onClick={downloadQRCode}>
                  Download QR Code
                </ButtonSection>
              </DownloadSection>
            </Spin>
          </>
        )}

        <Row>
          <Col className="m-auto" span={5}>
            HN :
          </Col>
          <Col span={19}>
            <Input
              onChange={(e) => {
                setPatientHn(e.target.value)
                addValueQrCode(amountQr, patientVn, e.target.value)
              }}
              value={patientHn}
              type="number"
              min={0}
              size="medium"
              style={{ width: '50%' }}
            />
          </Col>
          <Col className="m-auto" span={5}>
            VN :
          </Col>
          <Col span={19}>
            <Select
              showSearch
              value={patientVn}
              style={{ width: '50%' }}
              placeholder="เลือกหมายเลขวิสิต"
              onSelect={async (val) => {
                // console.log('val', val)
                const getObj = listVn.filter((item) => item._id == val)
                // console.log('getobj ',getObj[0]?.paymentSummarys)
                const getPrice = getObj[0]?.paymentSummarys[0]?.unrcptamt;
                const getVn = getObj[0]?.vn
                setPatientVn(getVn)
                addValueQrCode(getPrice, getVn)
              }}
            >
              {listVn?.map((item, index) => (
                <Option key={item.vn + index} value={item._id}>
                  {item.vn}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="mb-auto mt-auto" span={5}>
            จำนวน :
          </Col>
          <Col span={14}>
            <Input
              onChange={(e) => {
                addValueQrCode(e.target.value, patientVn, patientHn)
              }}
              type="number"
              min={0}
              size="medium"
              value={amountQr}
              style={{ width: '50%' }}
            />
            {'  '}บาท
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={visibleModalRecordSound}
        title={
          <Row gutter={24}>
            {showLink && state.recordList.length ? (
              <Col>
                <LeftOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setLinks()
                    setRecordList([])
                    setShowLink(false)
                  }}
                />
              </Col>
            ) : (
              ''
            )}
            <Col style={{ marginTop: 3 }}>ไฟล์วิดีโอ</Col>
          </Row>
        }
        footer={null}
        onOk={() => {
          setVisibleModalRecordSound(false)
          setSounds()
          setFilterSound(false)
          setLinks()
          setRecordList([])
          setShowLink(false)
        }}
        onCancel={() => {
          setState({ videoCompressLoading: false, videoLink: '' })
          setSounds()
          setVisibleModalRecordSound(false)
          setFilterSound(false)
          setLinks()
          setRecordList([])
          setShowLink(false)
        }}
        width={showLink && state.recordList && state.recordList.length ? 800 : '30%'}
      >
        <Spin indicator={antIcon} spinning={state.isLoading || state.soundLoading} tip="กรุณารอสักครู่...">
          {showLink && state.recordList && state.recordList.length ? <h3>ไฟล์แยกวิดีโอและเสียง</h3> : null}
          <div style={{ width: showLink && state.recordList && state.recordList.length ? '50%' : 'auto' }} className={showLink && state.recordList && state.recordList.length ? 'm-auto' : ''}>
            <Row gutter={16}>
              {showLink && state.recordList && state.recordList.length
                ? state.recordList.map((record, index) => (
                  <Col
                    key={record + index}
                    onClick={() => fetchRecordFile(soundSid, record)}
                    span={12}
                    style={{
                      padding: 20,
                      cursor: 'pointer',
                      border: '1px solid #e0e0e0',
                    }}
                  >
                    ไฟล์ {index + 1}
                  </Col>
                ))
                : state.sounds && state.sounds.length
                  ? state.sounds.map((sound, index) => (
                    <Col
                      onClick={async () => {
                        if (!state.links) {
                          await fetchRecordListFromRoomId(sound.sid)
                          await checkAndRenderCompositionVideo(sound.sid)
                          setSoundSid(sound.sid)
                          setShowLink(true)
                        }
                      }}
                      key={sound.createdAt + index}
                      span={12}
                      style={{
                        padding: 20,
                        cursor: 'pointer',
                        border: '1px solid #e0e0e0',
                      }}
                    >
                      {moment(sound.createdAt).format('DD/MM/YYYY')}
                    </Col>
                  ))
                  : 'ไม่มีไฟล์วิดีโอ'}
            </Row>
          </div>
          {showLink && state.recordList && state.recordList.length ? (
            <>
              <hr />
              <h3>ไฟล์รวมวิดีโอและเสียง</h3>
            </>
          ) : null}
          <Spin indicator={antIcon} spinning={state.videoCompressLoading} tip={state.tipText}>
            <style>{`
                  .video-react .video-react-big-play-button{
                    left: 45% ;
                    top : 45% ;
                    background-color : #45c1b887;
                  }
                `}</style>
            {showLink && state.recordList && state.recordList.length ? (
              <div className="p-4">
                <Player style={{ width: '500px' }} src={state.videoLink} />
                <DownloadSection style={{ paddingTop: 20 }}>
                  คลิกเพื่อ{' '}
                  <ButtonSection type="button" onClick={() => window.open(state.videoLink)}>
                    Download Video
                  </ButtonSection>
                </DownloadSection>
              </div>
            ) : null}
          </Spin>
        </Spin>
      </Modal>

      <Modal
        visible={visibleModalRecordNote}
        title={
          <Row gutter={24}>
            {showLink && (
              <Col>
                <LeftOutlined style={{ cursor: 'pointer' }} onClick={() => setShowLink(false)} />
              </Col>
            )}
            <Col style={{ marginTop: 3 }}>Note</Col>
          </Row>
        }
        footer={null}
        onOk={() => {
          setVisibleModalRecordNote(false)
          setShowLink(false)
          setNote(false)
        }}
        onCancel={() => {
          setVisibleModalRecordNote(false)
          setShowLink(false)
          setNote(false)
        }}
      >
        <Spin indicator={antIcon} spinning={state.isLoading} tip="กรุณารอสักครู่...">
          <Row gutter={16}>
            {showLink && note ? (
              <Col>
                <p className="h4 text-bold mb-2">{note.topic}</p>
                <p>{note.description}</p>
              </Col>
            ) : notes && notes.length ? (
              notes.map((note, index) => (
                <Col
                  onClick={() => {
                    setNote(note)
                    setShowLink(true)
                  }}
                  key={index}
                  span={12}
                  style={{
                    padding: 20,
                    cursor: 'pointer',
                    border: '1px solid #e0e0e0',
                  }}
                >
                  {note.topic}
                </Col>
              ))
            ) : (
              'ไม่มี Note'
            )}
          </Row>
        </Spin>
      </Modal>

      <Modal visible={visibleChatModal} title="Chat" width={800} footer={null} onOk={() => setVisibleChatModal(false)} onCancel={() => setVisibleChatModal(false)}>
        <Spin indicator={antIcon} spinning={state.isLoading} tip="กรุณารอสักครู่...">
          <ChatBody id="chatbody">
            <Container>
              {messages.length ? (
                messages &&
                messages.map((msg, index) => (
                  <div key={msg.text + index}>
                    <ChatMessage message={msg} doctorId={doctorId} />
                  </div>
                ))
              ) : (
                <Text className="text-center">ไม่มีประวัติการสนทนา</Text>
              )}
            </Container>
          </ChatBody>
        </Spin>
      </Modal>

      <Modal visible={visibleChatNumber} title="Chat" onOk={() => setVisibleChatNumber(false)} onCancel={() => setVisibleChatNumber(false)}>
        <Spin indicator={antIcon} spinning={state.isLoading} tip="กรุณารอสักครู่...">
          {Usermessages.length ? <Text className="text-center">{Usermessages.length} ข้อความ</Text> : <Text className="text-center">ไม่มีประวัติการสนทนา</Text>}
        </Spin>
      </Modal>

      <MedicalBenefitModal visibleMedicalBenefit={visibleMedicalBenefit} setVisibleMedicalBenefit={setVisibleMedicalBenefit} medicalBenefit={state.medicalBenefit} loading={state.isLoading} />

      <ReportProblemFormModal visibleReportProblemModal={visibleReportProblemModal} setVisibleReportProblemModal={setVisibleReportProblemModal}>
        <ReportProblemForm onSubmit={submitReportProblemForm}></ReportProblemForm>
      </ReportProblemFormModal>

      <ReportProblemModal visibleReportProblemInfoModal={visibleReportProblemInfoModal} setVisibleReportProblemInfoModal={setVisibleReportProblemInfoModal} noteProblems={noteProblems} />

      <CancelBookingFormModal visibleCancelBookingModal={visibleCancelBookingModal} setVisibleCancelBookingModal={setVisibleCancelBookingModal}>
        <CancelBookingForm onSubmit={submitCancleBookingForm}></CancelBookingForm>
      </CancelBookingFormModal>

      <CancelBookingModal visibleCancelBookingInfoModal={visibleCancelBookingInfoModal} setVisibleCancelBookingInfoModal={setVisibleCancelBookingInfoModal} cancelBookingCause={cancelBookingCause} />
    </Container>
  )
}
export default BookingTable
